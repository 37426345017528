/* global Cookies:true */
$(function() {
  'use strict';

  // signup
  var $signupDialog = $('#signup-dialog');

  // login
  var $loginDialog = $('#login-dialog');
  var $loginCancelButton = $loginDialog.find('input.cancel');
  $loginCancelButton.click(function() {
    $loginDialog.modal('hide');
  });

  // ログインが必要なコマンドを実行されたら、ログインダイアログを表示する。
  $(document).on('click', '.login-required', function(e) {
    e.preventDefault();
    if (isLoggedIn()) {
      return undefined;
    }
    $signupDialog.modal('hide');
    $loginDialog.modal('show');
    return false;
  });

  // ログインダイアログを表示する。
  $(document).on('click', '.show-login-dialog', function(e) {
    e.preventDefault();
    $signupDialog.modal('hide');
    $loginDialog.modal('show');
    return false;
  });

  // ログインフォーム
  const $loginForm = $('.login-form');
  const $loginFailed = $loginForm.find('.login-failed');

  // ログインダイアログ表示時のフォーカス操作
  $loginDialog.on('hide.bs.modal', function() {
    $loginFailed.hide();
  });

  function isLoggedIn() {
    return !!Cookies.get('authorization');
  }
});
